.image-editor {
  display: flex;
  height: 100vh;
}

.image-editor-canvas {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.image-editor-toolbar {
  background-color: #333;
  padding: 16px;
  width: 108px;
  display: flex;
  flex-direction: column;
  box-sizing: content-box;
}

.image-editor-toolbar-item {
  margin: 8px 0px;
  padding: 8px 16px;
  color: white;
  background-color: black;
  border-radius: 8px;
  box-shadow: 2px 4px 4px #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.image-editor-toolbar-item.disabled {
  color: #666;
}

.image-editor-toolbar-item:hover:not(.disabled) {
  background-color: #777;
}

.image-editor-option-label {
  color: #ddd;
  margin-right: 16px;
  font-size: 12px;
  flex-shrink: 0;
}

.image-editor-hor-option {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  margin: 4px 0px;
}

.image-editor-toolbar-item.selected {
  color: black;
  background-color: white;
}

.image-editor-color-palette {
  margin: 0px;
  width: 24px;
  height: 24px;
  border: 1px solid #222;
  flex-shrink: 0;
}

.image-editor-color-palette.selected {
  border-color: white;
}

.image-editor-edit-text-field {
  position: absolute;
  border: none;
  margin: 0px;
  background: none;
  outline: none;
  resize: none;
  transform-origin: left top;
  z-index: 10;
  min-width: 72px;
  min-height: 24px;
  white-space: pre-wrap;
  word-break: break-all;
  line-height: 1;
  vertical-align: top;
  margin-top: -1px;
  font-family: NotoSans;
  font-weight: normal;

  div {
    vertical-align: top;
    white-space: pre-wrap;
    line-height: 1;
    font-family: NotoSans;
  }
}

.image-editor-stage-wrapper {
  position: relative;
  outline: none;
  margin: 48px;
}

.image-editor-edit-text-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
}

.image-editor-overlay-toolbar {
  position: absolute;
  top: 4px;
  left: -24px;
  display: flex;
  flex-direction: column;
}

.image-editor-overlay-item {
  background-color: rgba(255, 255, 255, 0.75);
  border: 1px solid rgba(0, 0, 0, 0.75);
  width: 32px;
  height: 32px;
  margin: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-editor-overlay-item:hover {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 1);
}
