.cti-recall-date-picker {
  position: absolute;
  padding: 24px 16px;
  background: white;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);

  &.bottomleft,
  &.topleft {
    top: 102px;
    right: -20px;
    transform: translate(100%, 0);
  }

  &.bottomright,
  &.topright {
    top: 102px;
    left: -20px;
    transform: translate(-100%, 0);
  }
}
