@import 'variables';

.cti-status-bar {
  position: fixed;
  top: 64px;
  bottom: 0;
  box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: $status-bar-width;
  right: 0;

  .cti-hide-button {
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $light-blue;
    border-top: $border-color-blue;
    background: no-repeat center
      url('../../assets/images/icon/cti-hide-btn.svg');

    &.left {
      transform: scale(-1, 1);
    }
  }

  .cti-settings-button {
    width: 36px;
    height: 36px;
    border-radius: 0px !important;
    border-top: 1px solid $border-color-blue;
    border-bottom: 1px solid $border-color-blue;
    background: no-repeat center
      url('../../assets/images/icon/cti-settings-btn.svg');
  }

  .cti-status-bar-contents {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    z-index: 10;
    background-color: white;
  }
}

.cti-status-bar-hidden {
  position: fixed;
  bottom: 8px;
  height: 36px;
  width: $status-bar-width;
  background-color: white;
  color: $light-blue;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  z-index: 10;

  background: no-repeat center url('../../assets/images/icon/cti-show-btn.svg');

  &.left {
    left: 0;
    transform: scale(-1, 1);
  }

  &.right {
    right: 0;
  }
}
