.custom-toggle-button {
  width: 36px;
  border-radius: 8px !important;
  padding: 0px !important;
  margin: 0px;
  height: 16px;
  color: rgba(255, 255, 255, 0.8);
  position: relative;
  display: flex;
  border: none;
  cursor: pointer;

  .custom-toggle-button-switch {
    position: absolute;
    transition: left 100ms ease-in-out;
    border-radius: 100% !important;
    background-color: white;
    box-shadow: 0px 1px 2px rgab(0, 0, 0, 0.5);
    width: 16px;
    height: 16px;

    &.on {
      left: 20px;
      border: 1px solid #0060ff;
    }

    &.off {
      left: 0px;
      border: 1px solid #a3a3a3;
    }
  }

  .custom-toggle-button-on-text {
    position: absolute;
    bottom: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    font-size: 10px;
    left: 2px;
    transform: scale(0.8);
  }

  .custom-toggle-button-off-text {
    position: absolute;
    bottom: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    font-size: 8px;
    right: 2px;
    transform: scale(0.8);
  }

  &.off {
    background-color: #a3a3a3;
  }

  &.on {
    background-color: #0060ff;
  }
}
