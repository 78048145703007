.cti-settings-modal {
  width: 680px;
  padding: 0px !important;

  .modal-head {
    padding: 12px 36px;
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d1dcea;

    .title {
      font-size: 22px;
      font-weight: bold;
    }

    .close-icon-btn {
      width: 16px;
      height: 16px;
      background: no-repeat center
        url('../../assets/images/icon/cti-settings-close-btn.svg');
    }
  }

  .modal-body {
    margin-top: 0px;
    padding: 0px 20px;
  }

  .section {
    display: flex;
    padding: 30px 12px;

    .section-title {
      flex-shrink: 0;
      width: 120px;
      font-size: 14px;
      padding: 0px 16px;
    }

    .section-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding-left: 12px;

      .section-description {
        margin-bottom: 4px;

        font-family: AppleSDGothicNeo;
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.64;
        letter-spacing: normal;
        color: #898989;
      }

      .input-description {
        font-size: 11px;
        color: #898989;
      }

      .item-divider {
        border-bottom: 1px solid #d7e3f1;
        width: 100%;
        margin: 8px 0px;
      }

      .item-row {
        flex-wrap: wrap;
        display: flex;
        align-items: center;
        font-size: 14px;
        margin: 4px 0px;

        &:first-child {
          margin-top: 0px;
        }

        &:last-child {
          margin-bottom: 0px;
        }

        .custom-toggle-button {
          margin-right: 12px;
        }

        label {
          margin-right: 12px;
        }
        span.item-title {
          color: #4a4f70;
          font-size: 12px;
          margin-right: 20px;
        }

        input[type='checkbox'] {
          width: 16px;
          height: 16px;
          border: 1px solid #d5d5d5;
          margin-right: 12px;
        }

        input[type='radio'] {
          width: 16px;
          height: 16px;
          border: 1px solid #d5d5d5;
          margin-right: 12px;
        }

        input[type='number'].small:disabled {
          font-size: 14px;
          background-color: #efeff4!important;
          color: #ceced7;
        }

        input[type='number'].small {
          width: 64px;
          height: 30px;
          padding: 6px 12px;
          margin-right: 12px;
          border-radius: 4px;
          background-color: #f3f8ff;
          color: #000;
          font-size: 14px;
          border: 1px solid #dee2ec;
        }

        select {
          width: 80px;
          height: 30px;
          margin-right: 12px;
          font-size: 10px;
        }

        .location-items {
          display: flex;
          margin-top: 10px;
        }

        .location-option {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          margin: 0px 16px;

          &:first-child {
            margin-left: 0px;
          }

          &:last-child {
            margin-right: 0px;
          }

          .option-icon {
            margin-bottom: 8px;
            width: 64px;
            height: 45px;

            &.popup {
              &.bottomleft {
                background: no-repeat center
                  url('../../assets/images/icon/cti-settings-location-bottomleft.svg');
              }

              &.topleft {
                background: no-repeat center
                  url('../../assets/images/icon/cti-settings-location-topleft.svg');
              }

              &.bottomright {
                background: no-repeat center
                  url('../../assets/images/icon/cti-settings-location-bottomright.svg');
              }

              &.topright {
                background: no-repeat center
                  url('../../assets/images/icon/cti-settings-location-topright.svg');
              }
            }

            &.history {
              &.left {
                background: no-repeat center
                  url('../../assets/images/icon/cti-settings-history-loc-left.svg');
              }
              &.right {
                background: no-repeat center
                  url('../../assets/images/icon/cti-settings-history-loc-right.svg');
              }
            }
          }

          .option-name {
            width: 63px;
            padding: 6px 8px;

            font-family: AppleSDGothicNeo;
            font-size: 10px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            color: #3a3a3a;

            &.selected {
              background-color: #0060ff;
              color: white;
              border-radius: 4px;
            }
          }
        }
      }
    }

    border-bottom: 1px solid #d7e3f1;

    &:last-child {
      border-bottom: none;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;

    button {
      width: 84px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin: 8px;
      color: white;
    }

    .cancel-btn {
      background-color: #9cb2cd;
    }

    .save-btn {
      background-color: #0060ff;
    }
  }

  .autohide-option-row1 {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .autohide-option-row2 {
    display: flex;
    align-items: flex-end;
  }

  .info-option-item {
    width: 96px;
    display: flex;
    align-items: center;
  }

  .input-label {
    color: #4a4f70;
    font-size: 12px;
  }
}
