@import 'variables';
@import 'common';

.cti-history-filters {
  display: flex;
  padding: 16px 8px;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  select {
    border: 1px solid rgba(255, 255, 255, 0.4);
    color: white;
    padding-right: 32px;
    background-color: rgba(255, 255, 255, 0.05);
    background-image: none;
    margin-right: 24px;
    flex-shrink: 0;
    width: 100%;

    option {
      background-color: white;
      color: black;
    }
  }
}
