@import 'variables';

.cti-history-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $border-color-blue;

  button {
    border-radius: 0px !important;
  }

  .cti-history-open-icon {
    position: relative;
    width: 36px;
    height: 36px;
    background: no-repeat center
      url('../../assets/images/icon/cti-history-btn.svg');
    background-size: contain;

    &.calling {
      background: no-repeat center
        url('../../assets/images/icon/cti-history-btn-active.svg');
    }

    &.badge::after {
      content: '';
      position: absolute;
      top: 4px;
      right: 4px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #ff3c2f;
    }
  }
}
