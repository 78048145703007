.cti-history-items {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .cti-history-empty {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: AppleSDGothicNeo;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    color: #ffffff;
  }

  .list-header {
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 12px;
    padding-top: 16px;
    margin-bottom: 4px;

    .cti-date-label {
      display: flex;
      color: white;
      align-items: flex-end;

      .cti-date-name {
        font-size: 1rem;
        font-weight: 500;
        margin-right: 6px;
      }

      .cti-date-desc {
        font-size: 0.8rem;
      }
    }

    .hour-option-box {
      display: flex;
      align-items: flex-end;

      .hour-input {
        width: 22px;
        height: 22px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        background-color: rgba(255, 255, 255, 0.05);
        color: white;
        font-size: 11px;
        padding: 0px;
        margin-right: 4px;
        padding: 0px 3px;
        border-radius: 4px;

        &::-webkit-inner-spin-button {
          /* display: none; <- Crashes Chrome on hover */
          -webkit-appearance: none;
          margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }

        &[type='number'] {
          -moz-appearance: textfield; /* Firefox */
        }
      }

      .input-label {
        color: white;
        font-size: 8px;
      }
    }
  }

  .list-body {
    flex-grow: 1;
    overflow: overlay;

    &::-webkit-scrollbar {
      width: 8px;
      background-color: rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #cacaca;
      border-radius: 4px;
    }
  }

  .cti-history-divider {
    margin: 6px 8px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
}
