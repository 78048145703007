@import 'variables';
@import 'common';

.cti-call-popup {
  position: fixed;
  margin: 0px 16px;
  z-index: 10000;
  &.bottomleft {
    left: 0px;
    bottom: 44px;

    &.left {
      &.statusBar.history {
        left: $history-width;
      }
    }
  }

  &.topleft {
    left: 0px;
    top: 64px;

    &.left {
      &.statusBar.history {
        left: $history-width;
      }
    }
  }

  &.bottomright {
    right: 0px;
    bottom: 44px;

    &.right {
      &.statusBar {
        right: $status-bar-width;
      }

      &.statusBar.history {
        right: calc(#{$status-bar-width} + #{$history-width});
      }
    }

    &.left {
      &.statusBar {
        right: $status-bar-width;
      }
    }
  }

  &.topright {
    right: 0px;
    top: 64px;

    &.right {
      &.statusBar {
        right: $status-bar-width;
      }

      &.statusBar.history {
        right: calc(#{$status-bar-width} + #{$history-width});
      }
    }

    &.left {
      &.statusBar {
        right: $status-bar-width;
      }
    }
  }

  .call-popup-card {
    background-color: white;
    border-radius: 8px;
    width: $popup-width;
    overflow: hidden;
  }

  .cti-handle {
    user-select: none;
    display: flex;
    justify-content: flex-end;
    background-color: #009848;
    padding: 2px 8px;
    cursor: grab;

    button {
      margin: 2px 4px;
    }

    .cti-minimize-btn {
      width: 22px;
      height: 22px;
      background-image: url('../../assets/images/icon/cti-call-popup-minimize-btn.svg');
    }
    .cti-position-btn {
      width: 22px;
      height: 22px;
      background-image: url('../../assets/images/icon/cti-call-popup-position-btn.svg');
    }
    .cti-close-btn {
      width: 22px;
      height: 22px;
      background-image: url('../../assets/images/icon/cti-call-popup-close-btn.svg');
    }
  }

  .cti-header {
    background-color: #07cc64;
    display: flex;
    padding: 12px 16px;
    color: white;

    .cti-description-sub {
      font-size: 12px;
      margin-bottom: 6px;
    }

    .cti-description-main {
      font-size: 16px;
      font-weight: 800;
      display: flex;
      align-items: center;
      span {
        margin-right: 8px;
      }
      .cti-dashboard-btn {
        width: 16px;
        height: 16px;
        background-image: url('../../assets/images/icon/cti-dashboard.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  .cti-recall-form {
    padding: 16px 8px;
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    border: 1px solid #07cc64;
    border-top: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .cti-form-group {
      margin: 8px;
      display: flex;
      align-items: stretch;

      .cti-form-label {
        position: relative;
        font-size: 10px;
        font-weight: 500;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        font-family: 'Noto Sans KR';
        height: 15px;

        &.required {
          &::after {
            content: '*';
            height: 15px;
            margin-left: 2px;
            font-size: 15px;
            color: $blue;
          }
        }
      }
    }

    .cti-inline-form {
      display: flex;
    }

    .cti-form-control {
      margin: 0px 4px;

      &.half {
        width: 50%;
      }

      &.full-width {
        width: 100%;

        select {
          height: 30px;
          width: 100%;
        }
      }
    }

    .radio-group {
      display: flex;
      align-items: center;
      height: 34px;

      .radio-item {
        display: flex;
        align-items: center;
        margin-right: 20px;

        input {
          margin-right: 4px;
        }

        label {
          font-size: 10px;
        }
      }
    }

    .full {
      width: 100%;
    }

    .recall-checkbox {
      margin-left: 4px;
    }

    .recall-date-field {
      margin-right: 8px;
    }

    .counselor-select {
      width: 72px;
    }

    .cti-referral-input {
      border: 1px solid #dee2ec;
      border-radius: 4px;
      background-color: #f9fbff;
      display: flex;
      align-items: center;
      width: 100%;

      .referral-input-field {
        flex-grow: 1;
        padding: 4px 8px !important;
        padding-right: 4px !important;
        border: none !important;
        background: none !important;
        font-size: 10px !important;

        &::placeholder {
          font-size: 10px !important;
        }
      }

      .referral-input-btn {
        flex-shrink: 0;
        width: 22px;
        height: 22px;
        margin-right: 4px;
        background: no-repeat center
          url('../../assets/images/icon/cti-call-popup-search-referral.svg');
      }
    }

    input[type='text'] {
      height: 30px;
      font-size: 10px;
      padding: 8px;
      border-radius: 4px;
      background-color: #f3f8ff;
      color: #000;
      border: 1px solid #dee2ec;
      width: 100%;

      &::placeholder {
        font-size: 10px;
      }
    }

    select {
      height: 30px;
      font-size: 10px;
      background-color: #f9fbff;
      padding-right: 20px;
    }

    textarea {
      font-size: 10px;
      padding: 8px;
      height: 60px;

      &::placeholder {
        font-size: 10px;
      }
    }

    .menu-box {
      padding: 8px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .menu-item {
        width: 48px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .menu-icon {
          width: 32px;
          height: 32px;
          background-position: center;
          background-repeat: no-repeat;
          margin-bottom: 4px;
          &.history {
            background-image: url('../../assets/images/icon/cti-popup-history-btn.svg');
          }
          &.reservation {
            background-image: url('../../assets/images/icon/cti-popup-reservation-btn.svg');
          }

          &.calendar {
            background-image: url('../../assets/images/icon/cti-popup-calendar-btn.svg');
          }

          &.memo {
            background-image: url('../../assets/images/icon/cti-popup-memo-btn.svg');
          }
        }

        .menu-text {
          font-size: 10px;
        }

        &:active {
          .menu-icon {
            &.history {
              background-image: url('../../assets/images/icon/cti-popup-history-btn-active.svg');
            }
            &.reservation {
              background-image: url('../../assets/images/icon/cti-popup-reservation-btn-active.svg');
            }

            &.calendar {
              background-image: url('../../assets/images/icon/cti-popup-calendar-btn-active.svg');
            }

            &.memo {
              background-image: url('../../assets/images/icon/cti-popup-memo-btn-active.svg');
            }
          }
        }
      }
    }

    .goto-calendar-box {
      margin: 8px;
      margin-bottom: 28px;
    }

    .goto-calendar-btn {
      border: 1px solid #2d2d2d;
      width: 100%;
      height: 38px;
    }

    .action-box {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 4px;

      .complete-button {
        flex-grow: 1;
        padding: 12px;
        margin: 4px 4px;
        border-radius: 2px !important;
        background-color: #f7685b;

        font-family: NotoSansKR;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: white;
      }

      .save-button {
        flex-grow: 1;
        padding: 12px;
        margin: 4px 4px;
        border-radius: 2px !important;
        background-color: $blue;

        font-family: NotoSansKR;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: white;
      }

      .cancel-button {
        flex-grow: 1;
        margin: 4px 4px;
        padding: 12px;
        border: 2px solid #d7e3f1 !important;

        font-family: NotoSansKR;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;

        .blue {
          color: $blue;
        }

        .black {
          color: black;
        }

        .separator {
          margin: 0px 8px;
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  .save-result-flash {
    width: 280px;
    height: 44px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    position: absolute;
    top: 112px;
    background-color: white;
    left: 50%;
    transform: translate(-50%, 0);

    font-family: AppleSDGothicNeo;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.17;
    letter-spacing: normal;
    text-align: center;
    border: 1px solid #f7685b;

    &.success {
      border: 1px solid #0060ff;
      color: #0060ff;
    }
  }
}
