@import './variables.scss';

.cti-ringings {
  position: fixed;
  bottom: 0px;
  right: 32px;
  z-index: 8000;
  transition: right 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  pointer-events: none;
  padding-top: 64px;

  .ringings-list {
    position: relative;
    flex-grow: 1;
    overflow: overlay;
    pointer-events: all;

    &::-webkit-scrollbar {
      width: 8px;
      background-color: rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #cacaca;
      border-radius: 4px;
    }

    .inner {
      position: relative;
      margin-top: 24px;
    }

    .collapsed {
      left: 50%;
      transform: translate(-50%);
      position: absolute;
      height: 40px;
      border-radius: 16px;
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
      background-color: #303030;

      &.ringing {
        background-color: $green;
      }

      &.collapsed-0 {
        bottom: -8px;
        width: 340px;
      }

      &.collapsed-1 {
        bottom: -16px;
        width: 300px;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;

    .collapse-btn,
    .expand-btn,
    .close-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 16px;
      height: 32px;
      color: white;
      border-radius: 16px !important;
      box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.2);
      pointer-events: all;
    }

    .collapse-btn,
    .expand-btn {
      width: 120px;
      background-color: $green;
    }

    .close-btn {
      width: 80px;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  &.right {
    &.statusBar {
      right: calc(#{$status-bar-width});
    }

    &.statusBar.history {
      right: calc(#{$status-bar-width} + #{$history-width});
    }
  }

  &.left {
    &.statusBar {
      right: calc(#{$status-bar-width});
    }
  }
}
