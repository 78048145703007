$light-blue: #157ae6;
$dark-grey: #333;
$status-bar-width: 36px;
$history-width: 230px;
$popup-width: 320px;
$border-color-light: rgba(255, 255, 255, 0.4);
$border-color-blue: #d7e3f1;
$green: #07cc64;
$red: #f7685b;
$blue: #0060ff;
