.cti-textfield-wrapper {
  input {
    width: 100%;
  }

  &.error {
    input {
      border-color: #f7685b !important;
    }
  }

  .cti-form-error {
    font-family: NotoSansKR;
    font-size: 9px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f7685b;
  }
}
