.MuiTooltip-tooltip {
  background-color: rgba(0, 0, 0, 0.7);
}

.cti-input-slider {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  background: transparent !important; /* Otherwise white in Chrome */
  padding: 0px !important;
  border: none !important;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid #000000;
    height: 12px;
    width: 12px;
    margin-top: -6px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
  }

  &::-webkit-slider-runnable-track {
    height: 2px;
    cursor: pointer;
    background: black;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
  }

  &:focus::-webkit-slider-runnable-track {
    background: black;
  }
}
