$font-size: 13px;

.prescription {
  display: inline-block;
  padding: 20px;

  // 처방전 발급
  .wrap-action-btn {
    display: flex;
    float: right;
    button {
      margin-left: 10px;
    }
  }

  // 처방전 테이블
  .wrap-prescription-table,
  #prescription-table {
    width: 800px;
    flex: 0 0 auto;
    margin-right: 50px;
  }
  #prescription-table table {
    background: #fff;
    border-collapse: collapse;
    text-align: center;
    font-size: $font-size;
    line-height: 1;
    td {
      padding: 4px 8px;
      border: 1px solid #000;
      word-break: break-all;
    }
    select {
      width: 100%;
    }

    .title {
      padding: 8px;
      line-height: 1.5;
      h2 {
        font-size: 28px;
        margin-bottom: 5px;
      }
    }
    .btn-del {
      min-width: 20px;
    }
  }

  // 묶음 처방 리스트
  .wrap-prescription-bundle-list {
    min-width: 800px;
  }

  // 공통
  .top {
    vertical-align: top;
  }
  .center {
    text-align: center;
  }
  .left {
    text-align: left;
  }
  .right {
    text-align: right;
  }
  .hide {
    display: none;
  }
  .wrap-type-btn {
    display: inline-block;
    button {
      &::before {
        content: '[ ]';
        margin-right: 4px;
      }
      &.select::before {
        content: '[V]';
      }
      &.select {
        font-weight: bold;
      }
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}

.modal .modal-container .modal-serach-medicine {
  width: 800px;
  max-height: calc(100vh - 64px);
  .search-bar {
    display: flex;
    min-width: auto;
    button {
      flex: 0 0 auto;
    }
  }

  .data-table table td .item-content {
    white-space: break-spaces;
  }
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  body.printMode {
    .app-body,
    .app-body .flex-fill.route {
      overflow: visible;
      top: 0;
      left: 0;
    }

    * {
      visibility: hidden;
    }

    .left-navigation {
      display: none;
    }

    .wrap_print * {
      visibility: visible;
    }

    // 테이블 스크롤 때문에 프린트 영역이 길어지고 비율이 깨짐
    .prescription {
      width: 890px;
    }
    .prescription .data-table,
    .app-body .flex-fill.route .route-top,
    .wrap-prescription-bundle-list {
      display: none !important;
    }

    #prescription-table {
      display: block !important;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      table td {
        padding: 6px 8px;
      }
      .btn-del,
      select {
        display: none !important;
      }
      .doctor_name {
        display: block !important;
      }
      > div {
        position: relative;
        display: inline-block !important;
        padding: 5vw 5vw;
        width: 100%;
        height: 100%;
        margin: 0 auto;
      }
    }
    .type_personal button.personal {
      display: none;
    }
    .type_pharmacy button.pharmacy {
      display: none;
    }
    .print-type {
      button {
        margin: 0 !important;
        font-weight: normal !important;
      }
      &.wrap-type-btn button::before {
        content: '[';
        margin-right: 0;
      }
      &.wrap-type-btn button::after {
        content: ']';
      }
    }
  }
  input::placeholder {
    color: transparent !important;
  }
}
