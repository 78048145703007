:root {
  --appointment-cell-padding: 9px;
  --time-font-size: 10px;
  --category-font-size: 10px;
  --department-font-size: 11px;
  --cell-font-size: 12px;
}

.conditional-board.vertical.small {
  --appointment-cell-height: 32px;
  --appointment-cell-width: 40px;
  --date-height: 32px;
  --category-height: 32px;
  --department-height: 32px;
}

.conditional-board.vertical.normal {
  --appointment-cell-height: 40px;
  --appointment-cell-width: 50px;
  --date-height: 40px;
  --category-height: 40px;
  --department-height: 40px;
}

.conditional-board.vertical.big {
  --appointment-cell-height: 48px;
  --appointment-cell-width: 60px;
  --date-height: 48px;
  --category-height: 48px;
  --department-height: 48px;
}

.conditional-board.vertical.large {
  --appointment-cell-height: 56px;
  --appointment-cell-width: 70px;
  --date-height: 56px;
  --category-height: 56px;
  --department-height: 56px;
}

.conditional-board.vertical.xlarge {
  --appointment-cell-height: 64px;
  --appointment-cell-width: 80px;
  --date-height: 64px;
  --category-height: 64px;
  --department-height: 64px;
}

.conditional-board.vertical.xxlarge {
  --appointment-cell-height: 72px;
  --appointment-cell-width: 90px;
  --date-height: 72px;
  --category-height: 72px;
  --department-height: 72px;
}

.conditional-board.vertical.day {
  --date-height: 0px;
}

.conditional-board.horizontal.small {
  --appointment-cell-height: 32px;
  --appointment-cell-width: 40px;
  --date-width: 32px;
  --category-width: 48px;
  --department-width: 64px;
}

.conditional-board.horizontal.normal {
  --appointment-cell-height: 40px;
  --appointment-cell-width: 50px;
  --date-width: 40px;
  --category-width: 60px;
  --department-width: 80px;
}

.conditional-board.horizontal.big {
  --appointment-cell-height: 48px;
  --appointment-cell-width: 60px;
  --date-width: 48px;
  --category-width: 72px;
  --department-width: 96px;
}

.conditional-board.horizontal.large {
  --appointment-cell-height: 56px;
  --appointment-cell-width: 70px;
  --date-width: 56px;
  --category-width: 84px;
  --department-width: 112px;
}

.conditional-board.horizontal.xlarge {
  --appointment-cell-height: 64px;
  --appointment-cell-width: 80px;
  --date-width: 64px;
  --category-width: 96px;
  --department-width: 128px;
}

.conditional-board.horizontal.xxlarge {
  --appointment-cell-height: 72px;
  --appointment-cell-width: 90px;
  --date-width: 72px;
  --category-width: 108px;
  --department-width: 144px;
}

.conditional-board.horizontal.day {
  --date-width: 0px;
}

.calendar-departments-table {
  display: flex;
}

.vertical .calendar-departments-table {
  flex-direction: row;
  width: fit-content;
}

.horizontal .calendar-departments-table {
  flex-direction: column;
}

.department-category-name {
  background-color: #fff;
  text-align: center;
  line-height: 1;
  font-weight: 600;
  color: #273142;
  border-right: 1px solid #d7e3f1;
  border-bottom: 1px solid #d7e3f1;
  flex-shrink: 0;
  overflow: auto;
}

.vertical .department-category-name {
  border-top: 1px solid #d7e3f1;
  height: var(--category-height);
}

.horizontal .department-category-name {
  width: var(--category-width);
}

.calendar-cell {
  background-color: #fff;
  border-right: 1px solid #d7e3f1;
  border-bottom: 1px solid #d7e3f1;
  display: flex;
  cursor: -webkit-grab;
  position: relative;
}

.calendar-cell.lunch {
  background-color: rgb(246, 246, 246);
}

.vertical .calendar-cell.lunch {
  border-right: 1px solid #e9e9ed;
}

.horizontal .calendar-cell.lunch {
  border-bottom: 1px solid #e9e9ed;
}

.calendar-cell:hover,
.calendar-cell.lunch:hover {
  background: #a3c5e8;
}

.vertical .calendar-cell {
  width: 100%;
  height: auto;
}

.appointment-cell {
  position: absolute;
  border-radius: 2px;
  overflow: hidden;
  z-index: 1;
}

.appointment-cell .customer-name {
  word-break: break-all;
  display: flex;
}

.appointment-cell .category-item {
  word-break: break-all;
  display: flex;
}

.appointment-cell::-webkit-scrollbar {
  display: none;
}

.appointment-cell {
  width: var(--appointment-cell-width);
  height: var(--appointment-cell-height);
  line-height: 1.3;
  padding: 4px;
  padding-left: 0px;
  font-size: var(--cell-font-size);
}

.appointment-cell.scheduled {
  background: rgba(255, 194, 0, 0.6);
  border: 1px solid #ffc200;
  border-top: 4px solid #ffc200;
}

.appointment-cell.scheduled.cut {
  background: rgba(255, 194, 0, 0.2);
  border: 1px solid #ffc200;
  border-top: 4px solid #ffc200;
}

.appointment-cell.unvisited {
  background: rgba(39, 49, 66, 0.6);
  border: 1px solid #273142;
  border-top: 4px solid #273142;
}

.appointment-cell.unvisited.cut {
  background: rgba(39, 49, 66, 0.2);
  border: 1px solid #273142;
  border-top: 4px solid #273142;
}

.appointment-cell.consulting_waiting {
  background: rgba(0, 179, 233, 0.6);
  border: 1px solid #00b3e9;
  border-top: 4px solid #00b3e9;
}

.appointment-cell.consulting_waiting.cut {
  background: rgba(0, 179, 233, 0.2);
  border: 1px solid #00b3e9;
  border-top: 4px solid #00b3e9;
}

.appointment-cell.consulting_during {
  background: rgba(67, 138, 255, 0.6);
  border: 1px solid #438aff;
  border-top: 4px solid #438aff;
}

.appointment-cell.consulting_during.cut {
  background: rgba(67, 138, 255, 0.2);
  border: 1px solid #438aff;
  border-top: 4px solid #438aff;
}

.appointment-cell.treatment_waiting {
  background: rgba(137, 125, 255, 0.6);
  border: 1px solid #897dff;
  border-top: 4px solid #897dff;
}

.appointment-cell.treatment_waiting.cut {
  background: rgba(137, 125, 255, 0.2);
  border: 1px solid #897dff;
  border-top: 4px solid #897dff;
}

.appointment-cell.treatment_during {
  background: rgba(97, 84, 232, 0.6);
  border: 1px solid #6154e8;
  border-top: 4px solid #6154e8;
}

.appointment-cell.treatment_during.cut {
  background: rgba(97, 84, 232, 0.2);
  border: 1px solid #6154e8;
  border-top: 4px solid #6154e8;
}

.appointment-cell.surgery_waiting {
  background: rgba(255, 133, 56, 0.6);
  border: 1px solid #ff8538;
  border-top: 4px solid #ff8538;
}

.appointment-cell.surgery_waiting.cut {
  background: rgba(255, 133, 56, 0.2);
  border: 1px solid #ff8538;
  border-top: 4px solid #ff8538;
}

.appointment-cell.surgery_during {
  background: rgba(255, 80, 140, 0.6);
  border: 1px solid #ff508c;
  border-top: 4px solid #ff508c;
}

.appointment-cell.surgery_during.cut {
  background: rgba(255, 80, 140, 0.2);
  border: 1px solid #ff508c;
  border-top: 4px solid #ff508c;
}

.appointment-cell.payment_waiting {
  background: rgba(181, 233, 126, 0.6);
  border: 1px solid #b5e97e;
  border-top: 4px solid #b5e97e;
}

.appointment-cell.payment_waiting.cut {
  background: rgba(181, 233, 126, 0.2);
  border: 1px solid #b5e97e;
  border-top: 4px solid #b5e97e;
}

.appointment-cell.canceled {
  background: rgba(255, 133, 56, 0.6);
  border: 1px solid #ff4c4c;
  border-top: 4px solid #ff4c4c;
}

.appointment-cell.canceled.cut {
  background: rgba(255, 133, 56, 0.2);
  border: 1px solid #ff4c4c;
  border-top: 4px solid #ff4c4c;
}

.appointment-cell.consulting_request_done,
.appointment-cell.consulting_done,
.appointment-cell.treatment_done,
.appointment-cell.surgery_done,
.appointment-cell.payment_done,
.appointment-cell.complete {
  background: rgba(27, 161, 96, 0.6);
  border: 1px solid #1ba160;
  border-top: 4px solid #1ba160;
}

.appointment-cell.leave,
.appointment-cell.done,
.appointment-cell.fail_consulting_request,
.appointment-cell.fail_appointment,
.appointment-cell.fail_consulting,
.appointment-cell.fail_treatment,
.appointment-cell.fail_surgery,
.appointment-cell.fail_payment {
  background: rgba(162, 162, 162, 0.6);
  border: 1px solid #a2a2a2;
  border-top: 4px solid #a2a2a2;
}

.monthly-cell,
.app-body .appointment-boards .monthly-board .day-name {
  box-shadow: none;
  border: solid 1px #d7e3f1;
  border-left: 0;
  border-bottom: 0;
}

.monthly-cell {
  min-width: 200px;
  position: relative;
  padding: 0;
  box-shadow: none;
  height: 246px;
  overflow: auto;
}

.monthly-board .day-name {
  min-width: 200px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.16);
}

.monthly-cell .day {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  line-height: 15px;
  font-size: 12px;
  font-weight: bold;
  color: #9cb2cd;
  text-align: center;
}

.monthly-cell .day.day-6 {
  color: #005dff;
}

.monthly-cell .day.day-0,
.monthly-cell .day.holiday {
  color: #e02020;
}

.monthly-cell .appointment-items {
  padding: 0 15px 0 10px;
}

.monthly-cell .appointment-items > div {
  display: flex;
  width: auto;
  height: 30px;
  align-items: center;
  padding: 0;
  border: 0;
  margin: 0;
}

.monthly-cell .stats {
  font-size: 12px;
  padding-bottom: 15px;
}

.monthly-cell .more {
  left: 0;
  bottom: 0;
  font-size: 12px;
  font-weight: 700;
}

.monthly-cell .status {
  border-radius: 0 !important;
  border: 0 !important;
  background: none !important;
}

.monthly-cell .status.cell:hover {
  border: 0 !important;
}

.monthly-cell .status:hover {
  box-shadow: none;
}

.monthly-board .day-name {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.16);
}

.monthly-appointment-cell {
  font-size: 12px;
  line-height: 1;
}

.department-name-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #273142;
  word-break: break-all;
  overflow: auto;
  border-right: 1px solid #d7e3f1;
  border-bottom: 1px solid #d7e3f1;
  font-size: var(--department-font-size);
}

.vertical .department-name-cell {
  height: var(--department-height);
}

.horizontal .department-name-cell {
  width: var(--department-width);
}

.department-name-item {
  margin: auto;
}

.route.appointment-boards {
  overflow: hidden;
}

.appointment-boards .left-panel {
  overflow: auto;
}

.appointment-boards .right-panel {
  overflow: hidden;
}

.conditional-board.old {
  margin-top: 10px;
}

.conditional-board {
  margin-top: -1px;
  margin-left: -1px;
  background-color: #f3f8ff;
  border-left: 1px solid #d7e3f1;
  flex-grow: 1;
  min-height: 0px;
}

.right-panel {
  display: flex;
  flex-direction: column;
}

.wrap-table-leftmost {
  position: sticky;
  left: 0;
  z-index: 3;
}

.vertical .wrap-table-leftmost {
  min-width: 70px;
  left: 0;
}

.horizontal .wrap-table-leftmost {
  top: 0;
}

.pivot-cell {
  border-bottom: 1px solid #d7e3f1;
  background-color: #fff;
  border-top: 1px solid #d7e3f1;
}

.vertical .pivot-cell {
  border-right: 1px solid #d7e3f1;
  height: calc(
    var(--date-height) + var(--category-height) + var(--department-height)
  );
}

.horizontal .pivot-cell {
  width: calc(
    var(--date-width) + var(--category-width) + var(--department-width)
  );
}

.time-cell {
  display: flex;
  align-items: baseline;
  line-height: 0;
  font-size: 12px;
  font-weight: 600;
  text-align: right;
  color: #9cb2cd;
  background-color: #fff;
  padding: 8px;
  white-space: nowrap;
}

.vertical .time-cell {
  justify-content: flex-end;
  width: 70px;
  border-right: 1px solid #d7e3f1;
  border-bottom: 0;
  height: calc(var(--appointment-cell-height) + 1px);
}

.vertical .time-cell:last-child {
  border-bottom: 1px solid #d7e3f1;
}

.horizontal .time-cell {
  justify-content: flex-start;
  align-items: flex-end;
  height: 48px;
  border-top: 1px solid #d7e3f1;
  border-bottom: 1px solid #d7e3f1;
  padding-left: -24px;
  width: calc(var(--appointment-cell-width) + 1px);
}

.horizontal .time-cell:last-child {
  border-right: 1px solid #d7e3f1;
}

.time-cell-text {
  font-size: 10px;
}

.horizontal .time-cell-text {
  transform: translate(-22px, 0px);
}

.calendar-wrapper {
  overflow: auto;
  width: 100%;
  height: 100%;
}

.day-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  background-color: #fff;
  font-size: 12px;
  color: #000;
  border-right: 1px solid #d7e3f1;
  border-bottom: 1px solid #d7e3f1;
  flex-direction: column;
}

.vertical .day-cell {
  height: var(--date-height);
}

.horizontal .day-cell {
  width: var(--date-width);
}

.conditional-board.week .calendar-wrapper > div:last-child .day-cell {
  border-right: 1px solid #d7e3f1;
}

.day-cell.day-6 {
  color: #005dff;
}

.day-cell.day-0,
.day-cell.holiday {
  color: #e02020;
}

.left-panel-content-wrapper {
  padding-right: 16px;
  padding-left: 16px;
  padding-bottom: 60px;
  padding-top: 10px;
}

.calendar-fold {
  display: block;
  width: 100%;
  padding: 9px 9px 9px 30px;
  border-bottom: 1px solid #d7e3f1;
  text-align: left;
  font-size: 11px;
  font-weight: 700;
  color: #9cb2cd;
  background-color: #f9fbff;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAbCAYAAADyBeakAAAAAXNSR0IArs4c6QAAA9BJREFUSA21Vktv00AQ3lmXQ2nhP6CKK4ldcQDEo4gDxHZ6QIBUCWjs8CoIkDhxQIIicUHiwLvQpEUgJB4HlEcB8X71VocKwR/gH0ChHIiX2aRrrR/rUCFyyMzszjf+dnd2ZoEs4leuzuxijJxnlD50zewJAGB/Cy9VvO0M2AVCoOJa+jHE+gILQukkSzXPJYzcZIy1MQC3MJgjB1PFGK94u3FukhBGWz4Ad5db+p6dAE1utwdbM+q/GAHuytjeiZq3Q41qz8QItLFD36qNIYHtEopKJhLgzkB+Al3yRYXj44kEOBTILyDaZ4FN3YlUAqBZBXPVJxEoKtMIEKINOnbGExjlTqQR0Ag1C1b2tQgSlUoChMxTSgcLZvaZjEkkoSKASfgD74NZyOtv5CCynkYAKOQLpv5c9ud6jEQaAcztnGsab6NBhJ1GgAHYrmW8EL6yDJFQEcBUmqOg5Qpm5p0MlnUVgVYCa122m8u8lP1lPSCRRgCAbitYmfcyUNZTCWACO7nMK9k/qrdIqAhgVfqudcG24Vz2QxQobBUBnj+UgJWWwCIGLdcae0OVcGGGEwBKtw7ndCWBiXbBmQwqocCKBLb11wtDqaKL+f5ZbADtUiy7Aj3pWPq0PBTVm8QfxbFYrQHGTjn5fuUNisahjMJp3LqERuSfK1W9NVFAyGZwBvkHjUjM+QRGJ2qz64XdSdKiZZSRw8EoEeyWy7E/PMXjWqsKUswbt0EDN06E9fqs+Xi87m1QYeXx1lY6Vv8N3MJDMSKELMPjejI51Vgng2TdNfVJCqwYwzLWg3s0Va54G2X/JD2UC7j9B9HpatCuAwTM8Wvq2ln1Na15Dh7qeAyLja5V5lOSNJRUrm1cxw53OLoqzP5exvzHaefMjxXTe18My8hSTOB6qfZxIFhTRAmR4HOOZVzjRCJ+aHY+ZyzLJSCwP4kIY81aeWp2czxu0tVc8BqvNEYI8a9EQfiBVhNz8obyCpaqjX1Ieix6NHj287yHFO1wD4nthPhoMa9fxTw4ImwhMXAPPvDqWKg2ibGodG39ZuKNI6QbL0C1XPO2yJhQYsoTQsfH7RGfkUvCDiQmHNVgtZMzlK+rcmXmAK78WtKO4PNqDebgLI+n3AnxMcfuv4zvgKPCDiQmHDRZX2AnKFg1x3B4JJojWBm7cfUrBaQjCe6ICXeJAhwToJbEF/OwZdRDYwlG4o0DeLCiV38k3P+KBHd2bOMiAXocV4DPdHqHP9lxhbGSLQLLMrhxQH7jMdzr69WHBgbgt+yzKP3+9NfuRQEk53/BSmH+j/oHmsqrYIFNfbIAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-size: 11px;
  background-position: left 9px center;
  line-height: 0.9;
  border-radius: 0px !important;
}

.calendar-fold.full {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAASCAYAAABfJS4tAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAFqADAAQAAAABAAAAEgAAAADpoZWaAAACZklEQVQ4Eb1TTU8TURR9byhSDd0ZtSxL4lcjMA1/gK2EqigFMTExZc+CxB+gSxP/AcFIRISIREWlGvsHSD/SVKPRxsRE9wIdpqW93jNwp0M7bVw5mzf3vPPOvffc9/Tjzfypil17r7X6Ohg2p4eHdVX5fEuvvpy0aCeltPoRigSnEtFoxYfmQka1sv9SKRogohv535lnW1vU7e56fsq0u05KmUTq2nbJXl0pFo95tlt+DaX0T0FxKPcru5xOU0AwWbkjD4/iEG9XBM4YoUjPLa01Vy0fjZd2WsXZptss/sJlEcW5iLbiGkRkBoktuSIHOdnzSK85NTKi9wU75C0zb1wwTrY+GI4lmmfDVigFcKjPnABJDrDn17nyp15bwOsPmZNI2uCpq36zcSoWEioCCV4LprRe7e81p72VIxmSYuAuT+k1JBWeU7FsoiJua5IHuiaYIpoobWeerBB1CYbDbNNNJBUM9nhnc0QYJIgHA12z3Iolh/iaJXbfFC5KjBXiPd3BWb7XZcFh33crfwlxi/DSh8LpvVotxWLH5QB7OndndKAgMVbnYVWtlCJ1QnCtjbszl4eyiI94vLBRPFOv76XZ4/MNsp5LjsUeSoz18LV+5PajgkM0OWY+kNitePHdp/C/iKKjql1LdxKFuPPCIFqpWGlu/5xkRPvNlUK0bFUheqHBO1qpi8+nMn1kqzRP/6wL+oj62+QvCp2AsmmTB9BRFMRa3X7LPI/37UXBN4iMHH7w+bV/sIMpa2faB7zOog4HF//P6+x9rubzTDy2KELNK99RY34jd4+f/bfkqPmoef+/xX8Bv2Q8U9nBJ4UAAAAASUVORK5CYII=);
}

.calendar-board-table {
  display: flex;
}

.vertical .calendar-board-table {
  flex-direction: column;
}

.horizontal .calendar-board-table {
  flex-direction: row;
}

.calendar-categories {
  display: flex;
}

.vertical .calendar-categories {
  flex-direction: row;
  height: 100%;
}

.horizontal .calendar-categories {
  flex-direction: column;
}

.department-category {
  display: flex;
  align-items: stretch;
}

.vertical .department-category {
  flex-direction: column;
}

.horizontal .department-category {
  flex-direction: row;
}

.department-column {
  display: flex;
  align-items: stretch;
}

.vertical .department-column {
  flex-direction: column;
  width: fit-content;
}

.horizontal .department-column {
  flex-direction: row;
}

.calendar-dates-row {
  display: flex;
  position: sticky;
  z-index: 2;
}

.vertical .calendar-dates-row {
  flex-direction: row;
  height: var(--date-height);
  top: 0px;
  border-top: 1px solid #d7e3f1;
}

.horizontal .calendar-dates-row {
  flex-direction: column;
  width: var(--date-width);
  left: 0px;
}

.calendar-categories-row {
  display: flex;
  position: sticky;
  z-index: 2;
}

.vertical .calendar-categories-row {
  flex-direction: row;
  top: var(--date-height);
  height: var(--category-height);
}

.horizontal .calendar-categories-row {
  flex-direction: column;
  left: var(--date-width);
  width: var(--category-width);
}

.calendar-departments-row {
  display: flex;
  position: sticky;
  z-index: 2;
}

.vertical .calendar-departments-row {
  flex-direction: row;
  height: var(--department-height);
}

.horizontal .calendar-departments-row {
  flex-direction: column;
}

.vertical .calendar-departments-row {
  top: calc(var(--date-height) + var(--category-height));
}

.horizontal .calendar-departments-row {
  left: calc(var(--date-width) + var(--category-width));
}

.calendar-board-header-panel {
  position: relative;
  display: flex;
}

.vertical .calendar-board-header-panel {
  flex-direction: column;
}

.horizontal .calendar-board-header-panel {
  flex-direction: row;
}

.calendar-scroller-override {
  width: 100% !important;
  height: 100% !important;
  overflow: initial !important;
}

.calendar-padded {
  display: flex;
  padding-right: 300px;
  padding-bottom: 300px;
  width: fit-content;
  height: fit-content;
  position: relative;
}

.horizontal .calendar-padded {
  flex-direction: column;
}

.time-indicator-line,
.time-indicator-text-line {
  position: absolute;
  background-color: #0060ff;
  z-index: 1;
}

.vertical .time-indicator-line {
  height: 1px;
  width: 100%;
}

.horizontal .time-indicator-line {
  width: 1px;
  height: 100%;
}

.vertical .time-indicator-text-line {
  right: 0px;
  height: 1px;
  width: 34px;
}

.horizontal .time-indicator-text-line {
  bottom: 0px;
  width: 1px;
  height: 13px;
}

.time-indicator-text {
  z-index: 4;
  position: absolute;
  display: inline-block;
  border-radius: 2px;
  padding: 2px 3px;
  background-color: #0060ff;
  font-size: 10px;
  line-height: 1;
  text-align: center;
  color: #ffffff;
  vertical-align: middle;
  cursor: pointer;
}

.vertical .time-indicator-text {
  right: 34px;
  transform: translate(0, -50%);
}

.horizontal .time-indicator-text {
  bottom: 12px;
  transform: translate(-50%, 0);
}

.modal .modal-department-absense .body .flex-row {
  flex-direction: column;
  margin-top: 20px;
}
.modal .modal-department-absense .title {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
  flex-direction: row;
}
.modal .modal-department-absense .title-special {
  color: #0060ff;
  display: inline;
}

.size-select-panel {
  display: flex;
  align-items: center;
  margin-right: 32px;
  width: fit-content;
  flex-shrink: 0;
}

.size-select-panel select {
  width: 120px !important;
}

.size-select-panel > span {
  font-size: 0.8rem;
}

.appointment-boards .department-category-name {
  font-size: var(--category-font-size);
  font-weight: 600;
  color: #273142;
}

.calendar-header-count-panel {
  flex-grow: 1;
}
