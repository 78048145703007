.cti-guide-holder {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .cti-guide-tip {
    position: absolute;
    top: -12px;
    left: 50%;
    color: white;
    // transform: translate(-50%, -100%);
    border-radius: 4px;
    width: max-content;
    font-size: 10px;
    z-index: 10001;

    &.bottom {
      top: auto;
      bottom: -12px;
    }
  }

  .cti-guide-content {
    color: white;
    padding: 8px 16px;
    padding-right: 24px;
    border-radius: 4px;
    background-color: #0060ff;
    font-size: 10px;
    z-index: 10001;
  }

  .cti-guide-arrow {
    width: 8px;
    height: 8px;
    background-color: #0060ff;
    position: absolute;
    left: 50%;
    bottom: 1px;

    &.bottom {
      bottom: auto;
      top: 1px;
    }
  }

  .cti-guide-close-button {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 12px;
    height: 12px;
    background-image: url('../../assets/images/icon/cti-guide-close.svg');
    background-position: center;
  }
}
