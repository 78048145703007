@import 'variables';

.cti-history-item {
  position: relative;
  margin: 6px;
  margin-left: 5px;
  margin-right: 12px;
  border-radius: 8px;
  background-color: rgba(16, 16, 16, 0.54);
  padding-right: 8px;

  .cti-item-header {
    height: 20px;
    padding: 2px 8px;
    display: flex;
    justify-content: space-between;
    background-color: rgba(25, 25, 25, 0.8);
    color: rgba(249, 251, 255, 0.6);
    font-size: 9px;
    border-top-left-radius: 8px;
  }

  .cti-item-content {
    display: flex;
    align-items: center;
    padding: 8px 0px;
    color: white;

    .cti-status-icon {
      width: 30px;
      height: 30px;
      flex-shrink: 0;

      &.ringing {
        background-image: url('../../assets/images/icon/cti-call-ringing.png');
      }

      &.calling {
        background-image: url('../../assets/images/icon/cti-call-calling.png');
      }

      &.ended {
        background-image: url('../../assets/images/icon/cti-call-ended.png');
      }

      &.missed {
        background-image: url('../../assets/images/icon/cti-call-missed.png');
      }

      &.handled {
        background-image: url('../../assets/images/icon/cti-call-handled.png');
      }

      &.recall {
        background-image: url('../../assets/images/icon/cti-call-recall.png');
      }
    }
  }

  .cti-description {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color: white;

    .cti-description-primary {
      font-size: 10px;
      font-weight: 500;
      display: flex;
      align-items: center;

      .name {
        width: 137px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .cti-description-secondary {
      font-size: 10px;
      color: rgba(255, 255, 255, 0.8);
      display: flex;
      align-items: center;
    }

    .cti-dashboard-button {
      margin-left: 4px;
      width: 12px;
      height: 12px;
      background: no-repeat center
        url('../../assets/images/icon/cti-dashboard.png');
      background-size: contain;
    }
  }

  .cti-call-button {
    width: 29px;
    height: 29px;
    flex-shrink: 0;

    &.incoming {
      background-image: url('../../assets/images/icon/cti-call-receive-btn.png');
    }

    &.calling {
      background-image: url('../../assets/images/icon/cti-call-end-btn.svg');
    }

    &.connected {
      background-image: url('../../assets/images/icon/cti-call-onprogress-btn.svg');
    }

    &.inactive {
      background-image: url('../../assets/images/icon/cti-history-option-btn.svg');
    }
  }

  .cti-history-item-context-menu {
    position: absolute;
    border-radius: 2px;
    background-color: white;
    width: 101px;
    z-index: 20;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.8);

    .context-menu-item {
      width: 100%;
      height: 32px;
      font-size: 11px;
      border-radius: 0px !important;

      &:not(:last-child) {
        border-bottom: 1px solid #d7e3f1;
      }

      &:hover {
        background-color: #0060ff;
        color: white;
      }
    }
  }
}
