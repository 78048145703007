.item-content-memo {
  line-height: normal;
  padding: 0 6px !important;
}

.modal .modal-container .customer-payments.chart-page table.sub-data-table thead th.sub-line {
  background-color: #ffffff;
  border-bottom: 0;
}

.modal .modal-container .customer-payments.chart-page table.sub-data-table thead th:not(.sub-line),
.modal .modal-container .customer-payments.chart-page table.sub-data-table tbody td:not(.sub-line) {
  background-color: #f9f9f9;
}

.modal .modal-container .customer-payments.chart-page table.sub-data-table tbody td.sub-line .radius-box {
  width: 8px;
  height: 8px;
  max-width: 8px;
  border-radius: 50%;
  margin-right: 10px;
  border: 0;
  transition: 0.2s;
  background-color: #d7e3f1;
  float: right;
  position: absolute;
  right: -17px;
  top: 17px;
}

.modal .modal-container .modal-custom-chart .chart-conts.payment {
  padding-bottom: 0;
}

.modal .modal-container .customer-payments.chart-page .payment-sticky {
  overflow: scroll;
  max-height: 580px;
  min-height: 253px;
}

.modal .modal-container .customer-payments.chart-page .payment-sticky table,
.modal .modal-container .customer-payments.chart-page .payment-sticky table.sub-data-table {
  overflow: auto !important;
}

.modal .modal-container .customer-payments.chart-page .payment-sticky table thead th {
  background-color: #f3f8ff;
  position: sticky;
  top: 0;
  z-index: 2;
  text-align: left;
}

.modal .modal-container .customer-payments.chart-page .payment-sticky table.sub-data-table thead th {
  position: relative;
  z-index: 1;
}

.modal .modal-container .customer-payments.chart-page .payment-sticky table.merge-column > thead th:not(.th-colspan) {
  border-bottom: solid 1px #9cb2cd;
}

.modal .modal-container .customer-payments.chart-page .payment-sticky table.merge-column thead tr:nth-child(2) th {
  top: 37px;
  border-right: 1px solid #d7e3f1;
  border-bottom: solid 1px #9cb2cd;
}

.modal .modal-container .customer-payments.chart-page .payment-sticky table.merge-column thead th.sticky:nth-child(1) {
  left: 0px;
  z-index: 5;
}

.modal .modal-container .customer-payments.chart-page .payment-sticky table.merge-column tbody tr.merged td.sticky.column-1,
.modal .modal-container .customer-payments.chart-page .payment-sticky table.merge-column tbody tr td.sticky:nth-child(1) {
  position: sticky;
  background-color: #ffffff;
  z-index: 3;
  left: 0px;
}

.modal .modal-container .customer-payments.chart-page .payment-sticky table.merge-column thead th.sticky:nth-child(2) {
  left: 40px;
  z-index: 5;
}

.modal .modal-container .customer-payments.chart-page .payment-sticky table.merge-column tbody tr.merged td.sticky.column-2,
.modal .modal-container .customer-payments.chart-page .payment-sticky table.merge-column tbody tr td.sticky:nth-child(2) {
  position: sticky;
  background-color: #ffffff;
  z-index: 3;
  left: 40px;
}

.modal .modal-container .customer-payments.chart-page .payment-sticky table.merge-column thead th.sticky:nth-child(3) {
  left: 101px;
  z-index: 5;
}

.modal .modal-container .customer-payments.chart-page .payment-sticky table.merge-column tbody tr.merged td.sticky.column-3,
.modal .modal-container .customer-payments.chart-page .payment-sticky table.merge-column tbody tr td.sticky:nth-child(3) {
  position: sticky;
  background-color: #ffffff;
  z-index: 3;
  left: 101px;
}

.modal .modal-container .customer-payments.chart-page .payment-sticky table.merge-column thead th.sticky:nth-child(4) {
  left: 203px;
  z-index: 5;
}

.modal .modal-container .customer-payments.chart-page .payment-sticky table.merge-column tbody tr.merged td.sticky.column-4,
.modal .modal-container .customer-payments.chart-page .payment-sticky table.merge-column tbody tr td.sticky:nth-child(4) {
  position: sticky;
  background-color: #ffffff;
  z-index: 3;
  left: 203px;
}

.modal .modal-container .customer-payments.chart-page .payment-sticky table.merge-column thead th.sticky:nth-child(5) {
  left: 324px;
  z-index: 5;
}

.modal .modal-container .customer-payments.chart-page .payment-sticky table.merge-column tbody tr.merged td.sticky.column-5,
.modal .modal-container .customer-payments.chart-page .payment-sticky table.merge-column tbody tr td.sticky:nth-child(5) {
  position: sticky;
  background-color: #ffffff;
  z-index: 3;
  left: 324px;
}

.modal .modal-container .customer-payments.chart-page .payment-sticky table.sub-data-table thead .sub-sticky,
.modal .modal-container .customer-payments.chart-page .payment-sticky table.sub-data-table tbody .sub-sticky {
  position: sticky;
}

.modal .modal-container .customer-payments.chart-page .payment-sticky table.sub-data-table thead th.sub-sticky:nth-child(1),
.modal .modal-container .customer-payments.chart-page .payment-sticky table.sub-data-table tbody td.sub-sticky:nth-child(1) {
  position: sticky;
  background-color: #ffffff;
  left: 101px;
  z-index: 4;
  border-bottom: 0;
}

.modal .modal-container .customer-payments.chart-page .payment-sticky table.sub-data-table thead th.sub-sticky:nth-child(2),
.modal .modal-container .customer-payments.chart-page .payment-sticky table.sub-data-table tbody td.sub-sticky:nth-child(2) {
  position: sticky;
  left: 203px;
  z-index: 4;
}

.modal .modal-container .customer-payments.chart-page .payment-sticky table.sub-data-table thead th.sub-sticky:nth-child(3),
.modal .modal-container .customer-payments.chart-page .payment-sticky table.sub-data-table tbody td.sub-sticky:nth-child(3) {
  position: sticky;
  left: 324px;
  z-index: 4;
}

.modal .modal-container .customer-payments.chart-page .payment-sticky table.sub-data-table thead th.sub-sticky:nth-child(4),
.modal .modal-container .customer-payments.chart-page .payment-sticky table.sub-data-table tbody td.sub-sticky:nth-child(4) {
  position: sticky;
  left: 405px;
  z-index: 4;
}
.modal .modal-container .customer-payments.chart-page .payment-sticky table.sub-data-table tbody tr td:first-child,
.modal .modal-container .customer-payments.chart-page .payment-sticky table.sub-data-table tbody tr td.sub-sticky.title {
  border-bottom: 0;
  border-top: 0;
}

.modal .modal-container .customer-payments.chart-page .payment-sticky tr.sub-table-tr td.sub-table-padding-left {
  border-right: 0;
  background-color: #ffffff;
  /* position: sticky;
  left: 0; */
  z-index: 4;
 }

 .modal .modal-container .customer-payments.chart-page .payment-sticky tr.sub-table-tr td.sub-table-main-td {
  color: #d7e3f1;
  padding: 0;
 }

 .modal .modal-container .customer-payments.chart-page .payment-sticky tr.sub-table-tr td.sub-table-padding-right {
  background-color: #ffffff;
  /* position: sticky;
  left: 0; */
  z-index: 1;
 }