@import 'variables';

.cti-stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;

  .cti-stat-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 8px;
    border-bottom: 1px solid $border-color-blue;
    cursor: pointer;

    .cti-stat-label {
      width: 36px;
      height: 36px;
      background-size: contain;

      &.missed {
        background: no-repeat center
          url('../../assets/images/icon/cti-missed.svg');
      }

      &.recall {
        background: no-repeat center
          url('../../assets/images/icon/cti-recall.svg');
      }
    }

    .cti-stat-count {
      position: absolute;
      top: 4px;
      right: 4px;
      width: 14px;
      height: 14px;
      font-size: 10px;
      line-height: 10px;
      border-radius: 100%;
      background-color: $red;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
