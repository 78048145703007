.absence-schedules-calendar .date-functions > *, .appointment-boards .date-functions > *, .home .date-functions > * { max-width: 160px; }

.absence-schedules-calendar .date-functions > *:not(:last-child), .appointment-boards .date-functions > *:not(:last-child), .home .date-functions > *:not(:last-child) { margin-right: 8px; }

.absence-schedules-calendar .left-panel, .appointment-boards .left-panel, .home .left-panel { margin-right: 16px; }

.absence-schedules-calendar .left-panel .region, .absence-schedules-calendar .left-panel input, .absence-schedules-calendar .left-panel select, .appointment-boards .left-panel .region, .appointment-boards .left-panel input, .appointment-boards .left-panel select, .home .left-panel .region, .home .left-panel input, .home .left-panel select { font-size: 12px !important; }

.absence-schedules-calendar .left-panel .filters .filter:not(:last-child), .appointment-boards .left-panel .filters .filter:not(:last-child), .home .left-panel .filters .filter:not(:last-child) { margin-bottom: 8px; }

.absence-schedules-calendar .left-panel .filters select, .appointment-boards .left-panel .filters select, .home .left-panel .filters select { width: 100%; }

.appointment-boards { padding-bottom: 160px; }

.appointment-boards .overlay { position: fixed; z-index: 2; }

.appointment-boards .board .header .current-date { color: #400e40; font-size: 16px; text-align: center; }

.appointment-boards .table { 
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; 
  display: table; 
  border-left: 1px solid #d7e3f1;
}

.appointment-boards .cell { display: flex; align-items: center; justify-content: center; font-size: 12px; padding: 8px; width: 120px; height: 40px; border-right: 1px solid #E9E9ED; border-bottom: 1px solid #E9E9ED; overflow-y: auto; }

.appointment-boards .horizontal .department-category-name.colspan-1 { height: 100%; }

.appointment-boards .horizontal .department-category-name.colspan-2 { height: 200%; }

.appointment-boards .horizontal .department-category-name.colspan-3 { height: 300%; }

.appointment-boards .horizontal .department-category-name.colspan-4 { height: 400%; }

.appointment-boards .horizontal .department-category-name.colspan-5 { height: 500%; }

.appointment-boards .horizontal .department-category-name.colspan-6 { height: 600%; }

.appointment-boards .horizontal .department-category-name.colspan-7 { height: 700%; }

.appointment-boards .horizontal .department-category-name.colspan-8 { height: 800%; }

.appointment-boards .horizontal .department-category-name.colspan-9 { height: 900%; }

/* 주간,3일 캘린더 대부서 colspan */
.appointment-boards .week .department-category-name.colspan-1 { width: 50px; }
.appointment-boards .week .department-category-name.colspan-2 { width: 100px; }
.appointment-boards .week .department-category-name.colspan-3 { width: 150px; }
.appointment-boards .week .department-category-name.colspan-4 { width: 200px; }
.appointment-boards .week .department-category-name.colspan-5 { width: 250px; }
.appointment-boards .week .department-category-name.colspan-6 { width: 300px; }
.appointment-boards .week .department-category-name.colspan-7 { width: 350px; }
.appointment-boards .week .department-category-name.colspan-8 { width: 400px; }
.appointment-boards .week .department-category-name.colspan-9 { width: 450px; }
.appointment-boards .week .department-category-name.colspan-10 { width: 500px; }
.appointment-boards .week .department-category-name.colspan-11 { width: 550px; }
.appointment-boards .week .department-category-name.colspan-12 { width: 600px; }
.appointment-boards .week .department-category-name.colspan-13 { width: 650px; }
.appointment-boards .week .department-category-name.colspan-14 { width: 700px; }
.appointment-boards .week .department-category-name.colspan-15 { width: 750px; }
.appointment-boards .week .department-category-name.colspan-16 { width: 800px; }
.appointment-boards .week .department-category-name.colspan-17 { width: 850px; }
.appointment-boards .week .department-category-name.colspan-18 { width: 900px; }
.appointment-boards .week .department-category-name.colspan-19 { width: 950px; }
.appointment-boards .week .department-category-name.colspan-20 { width: 1000px; }
.appointment-boards .week .department-category-name.colspan-21 { width: 1050px; }
.appointment-boards .week .department-category-name.colspan-22 { width: 1100px; }
.appointment-boards .week .department-category-name.colspan-23 { width: 1150px; }
.appointment-boards .week .department-category-name.colspan-24 { width: 1200px; }
.appointment-boards .week .department-category-name.colspan-25 { width: 1250px; }
.appointment-boards .week .department-category-name.colspan-26 { width: 1300px; }
.appointment-boards .week .department-category-name.colspan-27 { width: 1350px; }
.appointment-boards .week .department-category-name.colspan-28 { width: 1400px; }
.appointment-boards .week .department-category-name.colspan-29 { width: 1450px; }
.appointment-boards .week .department-category-name.colspan-30 { width: 1500px; }
.appointment-boards .week .department-category-name.colspan-31 { width: 1550px; }
.appointment-boards .week .department-category-name.colspan-32 { width: 1600px; }
.appointment-boards .week .department-category-name.colspan-33 { width: 1650px; }
.appointment-boards .week .department-category-name.colspan-34 { width: 1700px; }
.appointment-boards .week .department-category-name.colspan-35 { width: 1750px; }

/* 주간,3일 캘린더 대부서 colspan > 세부부서 1개일때, department-depth-1 */
.appointment-boards .week .department-depth-1.department-category-name.colspan-1 { width: calc(50px * 1 - 5px * 0); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-2 { width: calc(50px * 2 - 5px * 1); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-3 { width: calc(50px * 3 - 5px * 2); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-4 { width: calc(50px * 4 - 5px * 3); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-5 { width: calc(50px * 5 - 5px * 4); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-6 { width: calc(50px * 6 - 5px * 5); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-7 { width: calc(50px * 7 - 5px * 6); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-8 { width: calc(50px * 8 - 5px * 7); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-9 { width: calc(50px * 9 - 5px * 8); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-10 { width: calc(50px * 10 - 5px * 9); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-11 { width: calc(50px * 11 - 5px * 10); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-12 { width: calc(50px * 12 - 5px * 11); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-13 { width: calc(50px * 13 - 5px * 12); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-14 { width: calc(50px * 14 - 5px * 13); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-15 { width: calc(50px * 15 - 5px * 14); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-16 { width: calc(50px * 16 - 5px * 15); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-17 { width: calc(50px * 17 - 5px * 16); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-18 { width: calc(50px * 18 - 5px * 17); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-19 { width: calc(50px * 19 - 5px * 18); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-20 { width: calc(50px * 20 - 5px * 19); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-21 { width: calc(50px * 21 - 5px * 20); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-22 { width: calc(50px * 22 - 5px * 21); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-23 { width: calc(50px * 23 - 5px * 22); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-24 { width: calc(50px * 24 - 5px * 23); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-25 { width: calc(50px * 25 - 5px * 24); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-26 { width: calc(50px * 26 - 5px * 25); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-27 { width: calc(50px * 27 - 5px * 26); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-28 { width: calc(50px * 28 - 5px * 27); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-29 { width: calc(50px * 29 - 5px * 28); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-30 { width: calc(50px * 30 - 5px * 29); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-31 { width: calc(50px * 31 - 5px * 30); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-32 { width: calc(50px * 32 - 5px * 31); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-33 { width: calc(50px * 33 - 5px * 32); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-34 { width: calc(50px * 34 - 5px * 33); }
.appointment-boards .week .department-depth-1.department-category-name.colspan-35 { width: calc(50px * 35 - 5px * 34); }

/* 세로모드, 가로모드 구분되면 위의 colspan은 삭제 예정 */
.appointment-boards .vertical .week .department-category-name.colspan-1 { width: 50px; }
.appointment-boards .vertical .week .department-category-name.colspan-2 { width: 100px; }
.appointment-boards .vertical .week .department-category-name.colspan-3 { width: 150px; }
.appointment-boards .vertical .week .department-category-name.colspan-4 { width: 200px; }
.appointment-boards .vertical .week .department-category-name.colspan-5 { width: 250px; }
.appointment-boards .vertical .week .department-category-name.colspan-6 { width: 300px; }
.appointment-boards .vertical .week .department-category-name.colspan-7 { width: 350px; }
.appointment-boards .vertical .week .department-category-name.colspan-8 { width: 400px; }
.appointment-boards .vertical .week .department-category-name.colspan-9 { width: 450px; }
.appointment-boards .vertical .week .department-category-name.colspan-10 { width: 500px; }
.appointment-boards .vertical .week .department-category-name.colspan-11 { width: 550px; }
.appointment-boards .vertical .week .department-category-name.colspan-12 { width: 600px; }
.appointment-boards .vertical .week .department-category-name.colspan-13 { width: 650px; }
.appointment-boards .vertical .week .department-category-name.colspan-14 { width: 700px; }
.appointment-boards .vertical .week .department-category-name.colspan-15 { width: 750px; }
.appointment-boards .vertical .week .department-category-name.colspan-16 { width: 800px; }
.appointment-boards .vertical .week .department-category-name.colspan-17 { width: 850px; }
.appointment-boards .vertical .week .department-category-name.colspan-18 { width: 900px; }
.appointment-boards .vertical .week .department-category-name.colspan-19 { width: 950px; }
.appointment-boards .vertical .week .department-category-name.colspan-20 { width: 1000px; }
.appointment-boards .vertical .week .department-category-name.colspan-21 { width: 1050px; }
.appointment-boards .vertical .week .department-category-name.colspan-22 { width: 1100px; }
.appointment-boards .vertical .week .department-category-name.colspan-23 { width: 1150px; }
.appointment-boards .vertical .week .department-category-name.colspan-24 { width: 1200px; }
.appointment-boards .vertical .week .department-category-name.colspan-25 { width: 1250px; }
.appointment-boards .vertical .week .department-category-name.colspan-26 { width: 1300px; }
.appointment-boards .vertical .week .department-category-name.colspan-27 { width: 1350px; }
.appointment-boards .vertical .week .department-category-name.colspan-28 { width: 1400px; }
.appointment-boards .vertical .week .department-category-name.colspan-29 { width: 1450px; }
.appointment-boards .vertical .week .department-category-name.colspan-30 { width: 1500px; }
.appointment-boards .vertical .week .department-category-name.colspan-31 { width: 1550px; }
.appointment-boards .vertical .week .department-category-name.colspan-32 { width: 1600px; }
.appointment-boards .vertical .week .department-category-name.colspan-33 { width: 1650px; }
.appointment-boards .vertical .week .department-category-name.colspan-34 { width: 1700px; }
.appointment-boards .vertical .week .department-category-name.colspan-35 { width: 1750px; }

.appointment-boards .draggable { cursor: move; cursor: grab; cursor: -moz-grab; cursor: -webkit-grab; }

.appointment-boards .draggable:active { cursor: grabbing; cursor: -moz-grabbing; cursor: -webkit-grabbing; }

.appointment-boards .draggable.dragging-over { border: 5px solid #f3f8ff; }

.appointment-boards .draggable.has-appointment { border-radius: 8px; overflow: hidden; }

.appointment-boards .regions { margin-top: 16px; display: grid; grid-template-columns: repeat(2, 1fr); grid-gap: 12px; }

.appointment-boards .regions .region { align-items: center; }

.appointment-boards .regions .region:not(:last-child) { margin-right: 16px; }

.appointment-boards .regions .region .box { max-width: 24px; width: 24px; height: 24px; border-radius: 4px; margin-right: 8px; }

.appointment-boards .status:not(.scheduled) { color: #FFF; }

.appointment-boards .status.scheduled { background: #FFF; border: 1px solid #E9E9ED; }

.appointment-boards .status.waiting { background: #57e1ec; }

.appointment-boards .status.treating { background: #2ac2e9; }

.appointment-boards .status.done { background: #484860; }

.appointment-boards .status.consulting { background: #e061ad; }

.appointment-boards .status.consulted { background: #bb84a5; }

.appointment-boards .status.unvisited { background: #fc983d; }

.appointment-boards .status.absence { background: #9494a5; }

.appointment-boards .status.lunch { background: #e2e2e6; }

.appointment-boards .status.cancled { background: #FF4C4C; }

.context-menu { position: relative; }

.context-menu ul { background: #FFF; position: absolute; border-radius: 4px; border: 1px solid #E9E9ED; box-shadow: 0 4px 12px rgba(0, 0, 0, 0.32); }

.context-menu ul li { padding: 6px 12px; min-width: 144px; }

.context-menu ul li:not(:last-child) { border-bottom: 1px solid #E9E9ED; }

.context-menu ul li:hover { color: #FFF; background: #ADACB0; cursor: pointer; }

.context-menu ul li.disabled { color: #ADACB0; }

.context-menu ul li i { font-size: 14px; }

.context-menu ul.menu-status { left: 144px; top: 0; }

.monthly-board { display: table; padding-right: 80px; padding-bottom: 80px; }

.monthly-board .first-line .day-name { color: #000; font-size: 16px; padding: 16px; }

.monthly-board .calendar { display: grid; grid-template-columns: repeat(7, 1fr); }

.monthly-board .appointment-items .item { 
  padding: 2px 6px; 
  border-radius: 4px; 
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; 
  cursor: pointer;
}

.monthly-board .appointment-items .item:not(:last-child) { margin-bottom: 4px; }

.monthly-board .btn-expand { margin-top: 24px; color: #FFF; background: #400e40; }

.monthly-board .cell { border-radius: 4px; height: initial; padding: 0; width: max-content; }

.monthly-board .cell:not(:last-child) { margin-bottom: 4px; }

.point-balance .point-balance-desc { font-size: 18px; line-height: 24px; font-weight: 800; }

.point-balance .point-balance-desc button { margin-left: 10px; }

.point-deposit-info span { font-size: 18px; line-height: 24px; font-weight: 800; }

.payment-info-confirm span { margin-right: 8px; }

.point-histories .card { margin-bottom: 24px; }

.payment-info-confirm, .point-apply-input { border: 1px solid #400e40; padding: 16px; }

.price-btn button:not(:last-child) { margin-right: 8px; }

.folder-cell { width: 40px; height: 40px; border: solid 1px; }

.folder-cell.select { background-color: gray; }

.message-send .card { border-radius: 4px; }

.message-send textarea { 
  border-radius: 4px; 
  border: 1px solid #dee2ec; 
  font-size: 16px; 
  padding: 4px; 
  width: 100%; 
}

.message-send .react-datepicker__input-container input { padding: 8px 38px 8px 19px !important; font-size: 14px !important; }

.message-send .react-datepicker__input-container input::placeholder { color: #ceced7 !important; text-align: left; }

.message-send .btn.btn-primary { border-radius: 3px; }

.message-send .message-screen-message-send { font-size: 1.1em; width: 380px; margin-bottom: 1em; border: 1px solid #ced4da; }

.message-send .target-container .set-target .btn { width: 100%; height: 34px; }

.message-send .condition-container .condition-groups .condition-group .condition-group-controller { background-color: #e9ecef; padding: 0 0.5em; justify-content: space-between; }

.message-send .condition-container .condition-groups .condition-group .condition-group-controller .btn { min-width: 10em; height: 2.5em; }

.message-send .condition-container .condition-groups .condition-group + .condition-group { border-top: 1px #cccccc solid; }

.message-send .condition-direct { flex-direction: column; }

.message-send .condition-direct .label { 
  margin: 20px 0 10px 0;
  display: block;
  width: 100%;
  padding: 0;
  font-size: 17px;
 }

.message-send .condition-direct .form { display: flex; width: 100%; }

.message-send .condition-direct .form .textarea { min-height: 96px; font-size: 1em; padding: 16px 20px; transition: none; }

.message-send .condition-direct .form .textarea:focus { box-shadow: none; border: solid 1px #d7e3f1; }

.message-send .condition-container .condition-search .condition-search-button { display: flex; flex-grow: 1; margin-right: 10px; }

.message-send .condition-container .condition-search .condition-search-button .btn { width: 100%; height: 2.5em; }

.message-send .condition-container .condition-search .condition-search-result { display: flex; flex-grow: 1; align-items: center; justify-content: space-between; padding: 0 20px; border: 1px solid #e9ecef; border-radius: 4px; background: #fff; }

.message-send .condition-row { display: flex; border: 1px solid #ced4da; font-size: 0.95em; height: 3.5em; align-items: center; }

.message-send .condition-row + .condition-row { border-top: none; border-top-width: 0; }

.message-send .condition-row .select { width: 100%; }

.message-send .condition-row .condition-label { display: flex; flex: 0 0 auto; align-items: center; font-size: 14px; width: 95px; height: 100%; font-size: 14px;}

.message-send .condition-row .condition-category { display: flex; width: 170px; }

.message-send .condition-row .condition-form { display: flex; max-width: 26em; width: 234px; margin-left: 10px; }

.message-send .condition-row .condition-form .null { display: flex; height: 36px; padding-left: 19px; align-items: center; font-size: 14px; border: 1px solid #dee2ec; background-color: #f2f2f2; color: #adb5bd; width: 100%; border-radius: 4px; }

.message-send .condition-row .condition-form .react-datepicker-wrapper { width: 234px; }

.message-send .condition-row .condition-form .search-item { margin-right: 0; }

.message-send .condition-row .condition-form .range-separator { display: none; }

.message-send .condition-row .condition-include { display: flex; width: 170px; margin-left: 10px; }

.message-send .condition-row .condition-remove-row { display: flex; width: 3em; }

.message-send .condition-row .select-condition-query__control .select-condition-query__value-container.select-condition-query__value-container--is-multi .select-condition-query__multi-value { background-color: #dee2e6; margin-right: 0.5em; }

.message-send .condition-row .select-condition-query__control .select-condition-query__value-container.select-condition-query__value-container--is-multi .select-condition-query__multi-value .select-condition-query__multi-value__remove:hover { background-color: #ffc9c9; }

.message-send .condition-row .select-condition-query__control .select-condition-query__value-container.select-condition-query__value-container--is-multi.select-condition-query__value-container--has-value input, .message-send .condition-row .select-condition-query__control .select-condition-query__value-container.select-condition-query__value-container--is-multi.select-condition-query__value-container--has-value textarea { height: 0; width: 0; }

.message-send .condition-row .select-condition-include .uno-select__option { cursor: pointer; }

.message-send .condition-row .select-condition-include .uno-select__option--is-selected { background-color: #228be6; color: white; }

.message-send .condition-row .select-condition-include .uno-select__option--is-focused { background-color: #74c0fc; color: white; }

.message-send .condition-row .select-condition-category__control, .message-send .condition-row .select-condition-query__control { border-radius: 4px; }

.message-send .condition-row .select-condition-category__group, .message-send .condition-row .select-condition-query__group { padding: 0; }

.message-send .condition-row .select-condition-category__group-heading, .message-send .condition-row .select-condition-query__group-heading { background-color: #e9ecef; padding: 0.7em 1em 0.2em 1.2em; color: #212529; }

.message-send .condition-row .select-condition-category__option, .message-send .condition-row .select-condition-query__option { cursor: pointer; }

.message-send .condition-row .select-condition-category__option--is-selected, .message-send .condition-row .select-condition-query__option--is-selected { background-color: #228be6; color: white; }

.message-send .condition-row .select-condition-category__option--is-focused, .message-send .condition-row .select-condition-query__option--is-focused { background-color: #74c0fc; color: white; }

.chart-page .card, .clinic-info .card { overflow-x: auto; }

.chart-page .card:not(:last-child), .clinic-info .card:not(:last-child) { margin-bottom: 24px; }

.appointment-codes > div, .consulting-codes > div, .customer-codes > div, .payment-codes > div, .sms-notification-codes > div, .treatment-codes > div, .user-codes > div { max-width: 400px; padding: 16px; border: 1px solid #400e40; }

.appointment-codes > div:not(:last-child), .consulting-codes > div:not(:last-child), .customer-codes > div:not(:last-child), .payment-codes > div:not(:last-child), .sms-notification-codes > div:not(:last-child), .treatment-codes > div:not(:last-child), .user-codes > div:not(:last-child) { margin-bottom: 24px; }

.alimtalk, .authority-group-editor, .boilerplate-messages, .department-categories, .products, .sms-auto-notifications, .treatment-item-categories { max-width: initial !important; }

.sms-send .sms-point { color: #ff3d6b; font-size: 16px; }

.sms-send .sms-targets { font-size: 18px; margin-bottom: 16px; }

.search-bar { display: flex; margin-bottom: 10px; align-items: center; }

.point-refund .title { font-size: 16px; font-weight: 800; }

.point-apply-finish .sub-title p, .refund-finish .sub-title p { font-size: 20px; margin-bottom: 16px; font-weight: 800; }

.point-apply-finish .notice p, .refund-finish .notice p { text-align: center; font-size: 20px; margin-bottom: 16px; }

.point-apply-finish .notice span, .refund-finish .notice span { font-size: 24px; margin-bottom: 16px; }

.modal-basic .danger-confrim { color: red; }

.organizations .organizations-view .organ-section { margin-bottom: 30px; }

.organizations .search-bar .title { font-weight: bold; margin-right: 10px; }

.organizations .tree-title { margin-bottom: 25px; background-color: #5c5b76; color: #ffffff; font-weight: bold; padding: 8px 5px; }

.organizations .organizations-tree { min-width: 400px; border: solid 1px #E9E9ED; margin-right: 10px; }

.organizations .organizations-tree .draggable-demo { max-height: 800px; overflow: scroll; padding: 10px 0; }

.organizations .organizations-tree .draggable-demo .rc-tree-iconEle.rc-tree-icon__customize { float: right; }

.organizations .organizations-tree .draggable-demo .rc-tree-iconEle.rc-tree-icon__customize .more { display: inline-block; position: relative; width: 100%; height: 100%; margin-left: 5px; z-index: 100; color: #000000; }

.organizations .data-area { max-height: 200px; overflow: scroll; }

.organizations .organizations-detail { height: 300px; }

.organizations .organizations-detail .profile { max-width: 200px; max-height: 200px; border: solid 1px #E9E9ED; margin-right: 15px; justify-content: center; align-items: center; }

.organizations .organizations-detail .profile img { width: 100px; height: 140px; }

.organizations .organizations-detail .profile-data { max-height: 200px; }

.organizations .organizations-detail .profile-data table { width: 100%; height: 100%; border: solid 1px #E9E9ED; }

.organizations .organizations-detail .profile-data th { color: #400e40; }

.organizations .user-contents .organizations-input .profile { max-width: 200px; }

.organizations .user-contents .organizations-input .profile img { width: 200px; height: 200px; }

.organizations .user-contents .modal-authority-settings { max-height: 500px; overflow: scroll; }

.sms-notification-codes .caller-numbers { max-width: 50em; }

.message-alimtalk { max-width: 42em !important; }

.message-alimtalk .item-group .guide-line { height: 2em; }

.message-alimtalk .item-group + .item-group { margin-top: 0.85em !important; }

.message-alimtalk .row.separator { padding: 0.5em 1em 0.5em 0.5em; margin: 0 0 0.1em; }

.message-alimtalk .row.separator .border { height: 1px; border-top-color: rgba(204, 204, 204, 0.514); border-top-width: 0.5px; border-top-style: solid; }

.message-alimtalk .category .uno-select__control { height: 2.5em; border: 1px solid #E9E9ED; }

.message-alimtalk input.phoneNumber:not([type=radio]) { font-size: 0.85rem; width: 100%; height: 2.5em; border: solid 1px #c7c7c7; padding-left: 0.5em; border-radius: 4px; border: 1px solid #E9E9ED; padding-top: 0.9rem; padding-bottom: 0.9rem; }

.message-alimtalk .uno-select__control.uno-select__control--is-disabled, .message-alimtalk input:not([type=radio]):not([type=checkbox]):disabled.item-input { background-color: #e9ecef; color: #212529; }

.message-alimtalk .uno-select__value-container { padding-left: 0.5em; }

.message-alimtalk .uno-select__value-container .uno-select__single-value { color: #212529; font-weight: 400; }

.message-alimtalk .help { background-color: #efefef; padding: 1em; border-color: #ccc; border-width: 1px; border-style: dotted; }

.message-alimtalk .help ul.desc { padding-left: 1em; font-size: 0.95em; padding-top: 0.5em; }

.message-alimtalk .help ul.desc li { padding-left: 0.5em; list-style-type: decimal; white-space: pre-wrap; }

.message-alimtalk .desc-response { font-size: 0.9em; display: flex; align-items: center; text-align: center; padding-left: 0.5em; }

.message-alimtalk .desc-response .success { color: #51cf66; }

.message-alimtalk .desc-response .error { color: #ff6b6b; }

.message-alimtalk .desc-col { padding-bottom: 0.5em; }

.message-alimtalk .btn.confirm { height: 3.0em; }

.connects .consulting-media { max-width: 400px; }

.connects .consulting-media .title { font-size: 16px; }

.connects .consulting-event { max-width: 900px; }

.connects .consulting-event .title { font-size: 16px; }

.connects .consulting-connect .title { font-size: 16px; }

.consulting-request-connect .tabs .tab { padding: 12px; border: 1px solid #E9E9ED; min-width: 200px; font-size: 16px; margin-right: 0 !important; }

.consulting-request-connect .tabs .tab:hover { cursor: pointer; }

.consulting-request-connect .tabs .tab.disabled { color: #ADACB0; }

.consulting-request-connect .flex-row > *:not(:last-child) { margin-right: 16px; }

.modal-event-registration button a { color: #000000; font-weight: 500; }

.modal-consulting-requests { min-width: 700px; }

.modal-consulting-requests table { width: 100%; }

.modal-consulting-requests span.comment { display: table; margin-left: auto; color: red; }

.counselors .subtitle { font-size: 15px; }

.new-customer { color: #ffffff; background-color: #6da42e; border-radius: 8px; }

.existing-customer { color: #ffffff; background-color: #5c5b76; border-radius: 8px; }

.modal-consulting { min-width: 1000px; }

.modal-consulting .tab { min-width: 80px !important; font-size: 12px !important; }

.modal-consulting .tab .selected { font-weight: bold; }

.modal-consulting .request-data { max-height: 200px; }

.modal-consulting .request-data table { width: 100%; height: 100%; border: solid 1px #E9E9ED; }

.modal-consulting .request-data th { color: #400e40; }

.modal-consulting .responsive-history-data .data-table, .modal-consulting .unresponsive-data .data-table { overflow: scroll; }

.modal-consulting .responsive-history-data span, .modal-consulting .unresponsive-data span { font-weight: bold; }

.modal-consulting .handling-data { position: relative; }

.modal-consulting .handling-data .dim { position: absolute; z-index: 100; height: 100px; width: 100%; background-color: rgba(216, 216, 216, 0.5); text-align: center; }

.modal-consulting .handling-data .dim div { position: relative; top: 50%; transform: translate(0, -50%); }

.modal-change-counselors table { min-width: 100%; text-align: center; }

.modal-change-counselors table button { margin: auto; }

.message-boilerplate-selector .message-caller-numbers, .message-codes .message-caller-numbers { width: 600px; }

.message-boilerplate-selector .message-boilerplate, .message-codes .message-boilerplate { width: 1050px; }

.message-boilerplate-selector .message-auto-notification, .message-codes .message-auto-notification { width: 1280px; }

.message-boilerplate-selector .data-table td.category .item-content, .message-codes .data-table td.category .item-content { justify-content: flex-start; padding-left: 1em; text-align: left; word-break: break-all; white-space: pre-wrap; }

.message-boilerplate-selector .data-table td.title-and-content .item-content, .message-codes .data-table td.title-and-content .item-content { margin: 0.5em 0; padding: 0; white-space: initial; justify-content: flex-start; padding-left: 1em; text-align: left; }

.message-boilerplate-selector .data-table td.title-and-content .item-content label, .message-codes .data-table td.title-and-content .item-content label { flex: 0 0 auto; }

.message-boilerplate-selector .data-table td.title-and-content .item-content .contents, .message-boilerplate-selector .data-table td.title-and-content .item-content .title, .message-codes .data-table td.title-and-content .item-content .contents, .message-codes .data-table td.title-and-content .item-content .title { display: flex; flex-direction: row; margin-right: 1em; white-space: pre-line; border: 1px solid #f1f3f5; }

.message-boilerplate-selector .data-table td.title-and-content .item-content .contents .label, .message-boilerplate-selector .data-table td.title-and-content .item-content .title .label, .message-codes .data-table td.title-and-content .item-content .contents .label, .message-codes .data-table td.title-and-content .item-content .title .label { font-weight: 600; padding: 1em; min-width: 2em; align-self: center; }

.message-boilerplate-selector .data-table td.title-and-content .item-content .contents .text, .message-boilerplate-selector .data-table td.title-and-content .item-content .title .text, .message-codes .data-table td.title-and-content .item-content .contents .text, .message-codes .data-table td.title-and-content .item-content .title .text { border-left: 1px solid #e9ecef; padding: 1em; justify-content: center; }

.message-boilerplate-selector .data-table td.title-and-content .item-content .title + .contents, .message-codes .data-table td.title-and-content .item-content .title + .contents { border-top: 0; }

.message-history-container { border: 1px solid #400e40; padding: 2em 1em 1em; background-color: white; }

.message-history-container .tabs { display: flex; font-size: 1.9em; font-weight: 600; height: 1em; padding-bottom: 1.5em; }

.message-history-container .tabs .tab { color: #ced4da; margin-right: 0.5em; }

.message-history-container .tabs .tab.selected { color: #212529; }

.message-history-container .tabs .tab:hover { cursor: pointer; }

.message-history-container .search-inputs, .sms-use-list .search-inputs { display: flex; }

.message-history-container .search-inputs .btn, .message-history-container .search-inputs .input-datepicker,
.sms-use-list .search-inputs .btn, .sms-use-list .search-inputs .input-datepicker { border-radius: 3px; border: 1px solid; border-color: #f1f3f5; }

.message-history-container .data-table > td { min-width: none; }

.message-codes .message-map { width: 1050px; }