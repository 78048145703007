@import 'variables';

.cti-all-memo-viewer {
  position: fixed;
  cursor: grab;

  &.bottomleft {
    bottom: 44px;
    left: calc(#{$popup-width} + 48px);

    &.left.history {
      left: calc(#{$history-width} + #{$popup-width} + 48px);
    }
  }

  &.topleft {
    top: 64px;
    left: calc(#{$popup-width} + 48px);

    &.left.history {
      left: calc(#{$history-width} + #{$popup-width} + 48px);
    }
  }

  &.bottomright {
    right: calc(#{$popup-width} + 48px);
    bottom: 44px;

    &.statusBar {
      right: calc(#{$status-bar-width} + #{$popup-width} + 48px);
    }

    &.right {
      &.statusBar.history {
        right: calc(
          #{$status-bar-width} + #{$history-width} + #{$popup-width} + 48px
        );
      }
    }
  }

  &.topright {
    right: calc(#{$popup-width} + 48px);
    top: 64px;

    &.statusBar {
      right: calc(#{$status-bar-width} + #{$popup-width} + 48px);
    }

    &.right {
      &.statusBar.history {
        right: calc(
          #{$status-bar-width} + #{$history-width} + #{$popup-width} + 48px
        );
      }
    }
  }

  .inner {
    position: relative;
    width: 540px;
    height: 730px;
    background-color: white;
    margin: 1px;
    margin-top: 4px;
    border-radius: 4px;
    padding: 16px;
    overflow: auto;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, #ff8950 0%, #ff508c 100%);
    border-radius: 4px;
  }

  .head {
    > div {
      display: flex;
      justify-content: space-between;
      padding: 12px 0px;

      &:first-child {
        padding-top: 0px;
      }
    }

    .zmdi {
      cursor: pointer;
    }

    .search-form {
      select {
        width: 140px;
        background-color: #f3f8ff;
        font-size: 12px;
      }

      button {
        width: 84px;
      }
    }
  }

  .body {
    .data-table th,
    .data-table td {
      font-size: 12px;
    }

    .data-table table td {
      padding: 0px;
    }

    .data-table th,
    .data-table td {
      padding: 12px 0px;
    }
  }
}
