.cti-datepicker-input {
  width: 94px;
  .inner {
    display: flex;
    align-items: center;
    position: relative;
  }

  .datepicker-input[type='text']:disabled {
    background-color: #efeff4!important;
    color: #ceced7;
  }

  .datepicker-input[type='text'] {
    flex-grow: 1;
    height: 30px;
    padding: 4px 8px;
    padding-right: 30px;
    border: 1px solid #dee2ec;
    border-radius: 4px;

    background: #f3f8ff !important;
    font-size: 10px !important;

    color: #000;
    width: 100%;

    &::placeholder {
      font-size: 10px !important;
    }

    &.error {
      border-color: #f7685b;
    }
  }

  button {
    right: 4px;
    position: absolute;
    flex-shrink: 0;
    width: 22px;
    height: 22px;
    background: no-repeat center
      url('../../assets/images/icon/cti-recall-calendar-btn.svg');
  }

  .cti-form-error {
    font-family: NotoSansKR;
    font-size: 9px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f7685b;
  }
}
