.cti-popup-close-modal {
  padding: 0px !important;

  .modal-head {
    padding: 12px 36px;
    padding-top: 24px;
    display: flex;
    justify-content: flex-end;

    .close-icon-btn {
      width: 16px;
      height: 16px;
      background: no-repeat center
        url('../../assets/images/icon/cti-settings-close-btn.svg');
    }
  }

  .message-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;

    .title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    .warn {
      font-size: 14px;
      color: #f7685b;
      text-decoration: underline;
    }

    .message {
      font-size: 14px;
      margin-bottom: 12px;
    }
  }

  .actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;

    button {
      margin: 10px;
      width: 66px;
      padding: 9px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .btn-close {
      background-color: #1b73e8;
      color: white;
    }
  }

  .btn-outlined {
    border: 1px solid #1b73e8;
    color: #1b73e8;
  }
}
