@import './variables.scss';

.cti-ringing-popup {
  border-radius: 8px;
  display: flex;
  color: white;
  pointer-events: all;
  position: relative;

  box-shadow: 4px 8px 12px rgba(0, 0, 0, 0.2);
  transition: all 1s ease-in-out;
  font-family: 'Noto Sans KR';
  width: 372px;
  margin: 16px 12px;
  background-color: rgba(7, 204, 100, 0.94);

  .profile-box {
    padding: 16px;
    flex-grow: 1;
    border-right: 1px solid $border-color-light;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .profile-head {
      margin: 0px 4px;
      display: flex;
      align-items: center;
      font-size: 16px;

      .profile-name {
        margin-right: 16px;
      }

      .dashboard-button {
        width: 20px;
        height: 20px;
        background-image: url('../../assets/images/icon/cti-dashboard.png');
      }
    }

    .profile-detail {
      display: flex;
      margin: 4px;
      overflow: hidden;
      white-space: nowrap;
      font-size: 12px;
      width: 260px;

      .profile-separator {
        margin: 0px 4px;
      }

      .profile-description {
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .action-box {
    width: 60px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    .start-button {
      font-weight: 800;
      border-radius: 0px !important;
      color: white;
      flex-grow: 1;
      border-bottom: 1px solid $border-color-light;
    }

    .close-button {
      font-weight: 800;
      color: white;
      flex-grow: 1;
    }
  }
}
