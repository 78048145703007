@import 'variables';

.cti-history {
  position: fixed;
  top: 64px;
  bottom: 0px;
  width: $history-width;
  background-color: #303030;
  display: flex;
  flex-direction: column;
  z-index: 10;

  &.left {
    left: 0px;
  }

  &.right {
    right: $status-bar-width;
  }

  .cti-panel-handle {
    background-color: #1c1c1c;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 28px;
    padding: 2px 8px;
    flex-shrink: 0;
    cursor: grab;

    button {
      width: 16px;
      height: 16px;
      background-image: url('../../assets/images/icon/cti-close.png');
    }
  }

  .cti-panel-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 8px;
    height: 44px;
    flex-shrink: 0;
    font-size: 11px;
    color: white;

    .footer-text {
      margin: 0px 8px;
    }

    .footer-help {
      width: 16px;
      height: 16px;
      background-image: url('../../assets/images/icon/cti-help.png');
    }
  }
}
