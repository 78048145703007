.vertical .calendar-cell {
  min-height: calc(var(--appointment-cell-height) + 1px);
  min-width: calc( (var(--appointment-cell-width) + 1px) * 1 + var(--appointment-cell-padding) );

  @for $i from 1 to 100 {
    &.size-#{$i} {
      min-width: calc(
        (var(--appointment-cell-width) + 1px) * #{$i} + var(--appointment-cell-padding)
      );
    }
  }
}

.horizontal .calendar-cell {
  min-height: calc( var(--appointment-cell-height) + 2px + var(--appointment-cell-padding) );
  min-width: calc(var(--appointment-cell-width) + 1px);

  @for $i from 2 to 100 {
    &.size-#{$i} {
      min-height: calc( (var(--appointment-cell-height) + 2px) * #{$i} + var(--appointment-cell-padding) );
    }
  }
}

/* 동시간대, 같은 부서에 대해 30건에 대응 >> 일간 */
.vertical .appointment-cell {

  @for $i from 0 to 100 {
    &.appointment-#{$i} {
      left: calc((var(--appointment-cell-width) + 1px) * #{$i});
    }
  }
}

.horizontal .appointment-cell {

  &.appointment-0 {
    top: 0px;
  }

  &.appointment-1 {
    top: calc(var(--appointment-cell-height) + 2px);
  }

  @for $i from 2 to 100 {
    &.appointment-#{$i} {
      top: calc((var(--appointment-cell-height) + 2px) * #{$i});
    }
  }
}